import { SelectableValue, TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Yesterday', parent: 'Daily' },
  { from: 'now/d', to: 'now/d', display: 'Today', parent: 'Daily' },
  { from: 'now+1d/d', to: 'now+1d/d', display: 'Tomorrow', parent: 'Daily' },

  { from: 'now/w', to: 'now+2w', display: 'Two weeks', parent: 'Period' },
  { from: 'now/w', to: 'now+4w', display: 'Four weeks', parent: 'Period' },
  { from: 'now/w', to: 'now+8w', display: 'Eight weeks', parent: 'Period' },

  { from: 'now-1w/w', to: 'now-1w/w', display: 'Last week', parent: 'Weekly' },
  { from: 'now/w', to: 'now/w', display: 'This week', parent: 'Weekly' },
  { from: 'now+1w/w', to: 'now+1w/w', display: 'Next week', parent: 'Weekly' },

  { from: 'now-1M/M', to: 'now-1M/M', display: 'Last month', parent: 'Monthly' },
  { from: 'now/M', to: 'now/M', display: 'This month', parent: 'Monthly' },
  { from: 'now+1M/M', to: 'now+1M/M', display: 'Next month', parent: 'Monthly' },
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];
